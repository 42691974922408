<template>
    <CRow>
        <CCol sm="12">
            <ListForm :headerTitle="$t('common.historyPoints')" :onSearch="onSearch">
                <template v-slot:searchBox>
                    <CForm>
                        <CRow>
                            <CCol md="5">
                                <div role="group" class="form-group form-row">
                                    <label class="col-form-label col-sm-3">{{$t('common.startDate')}} </label>
                                    <div class="col-sm-9">
                                        <date-picker v-model="startDate" format="DD/MM/YYYY"></date-picker>
                                    </div>
                                </div>
                            </CCol>
                            <CCol md="7">
                                <div role="group" class="form-group form-row">
                                    <label class="col-form-label col-sm-3">{{$t('common.endDate')}} </label>
                                    <div class="col-sm-9">
                                        <date-picker v-model="endDate" format="DD/MM/YYYY"></date-picker>
                                    </div>
                                </div>
                            </CCol>
                        </CRow>                       
                    </CForm>
                </template>
                <template v-slot:list>
                    <CDataTable :items="pointsTrabsactionList"
                                :fields="fields"
                                hover
                                striped
                                border
                                small
                                fixed
                                @page-change="onPageChanged"
                                :loading="isLoading"
                                :items-per-page="itemsPerPage">
                        <template #createdDate="{item}">
                            <td class="py-2">
                                <label v-text="formatDate(item.createdDate)" />
                            </td>
                        </template>
                        <template #transactionType="{item}">
                            <td class="py-2">                               
                                <label v-text="transactionType(item.transactionTypeId)" />
                            </td>
                        </template>
                        <template #deposit_detail_actions="{item, index}">
                            <td class="py-2">
                                <CButton color="primary"
                                         variant="outline"
                                         square
                                         size="sm"
                                         @click="onViewDetail(item, index)">
                                    {{ $t('common.detail') }}
                                </CButton>
                            </td>
                        </template>
                    </CDataTable>
                    <CPagination :pages="totalPages"
                                 :activePage="pageIndex + 1"
                                 @update:activePage="onPageChanged"></CPagination>
                </template>
            </ListForm>           
        </CCol>
    </CRow>
</template>

<script>
    import { mapActions, mapState } from 'vuex'
    import i18n from '@/plugins/i18n'   
    import ListForm from '@/components/ListForm.vue'
    import TransactionStatus from '@/helpers/TransactionStatus'
    
    export default {
        name: 'RewardPoints',
        data() {
            return {                                             
                startDate: null,
                endDate: null,
                itemsPerPage: 10,
                warningModal: false,
                fields: [
                    { key: 'createdDate', label: i18n.t('common.createdDate') },
                    { key: 'transactionType', label: i18n.t('pages.deposit.transactionType') },
                    { key: 'pointDisplay', label: i18n.t('pages.deposit.points') },
                    { key: 'createdBy', label: i18n.t('pages.deposit.remitterName') },
                ]
            };
        },
        components: {
            ListForm,           
        },
        computed: {            
            ...mapState('rewardpoint', ['isLoading', 'pointsTrabsactionList', 'totalPages', 'pageIndex'])
        },
        methods: {
            ...mapActions('rewardpoint', ['searchTransactionsOfRewardPoints']),

            formatDate(date) {
                if (date === undefined)
                    return '';
                return this.$moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss');
            },
            transactionType(iType) {

                if (TransactionStatus.Payment === iType)
                    return i18n.t('pages.deposit.payment');
                else if (TransactionStatus.Deposit === iType)
                    return i18n.t('pages.deposit.deposit');
                else if (TransactionStatus.Withdraw === iType)
                    return i18n.t('pages.deposit.withdraw');
                else if (TransactionStatus.SharePoints === iType)
                    return i18n.t('pages.deposit.sharePoints');
                else if (TransactionStatus.ExchangeStock === iType)
                    return i18n.t('pages.deposit.exchangeStock');
                else if (TransactionStatus.RewardPoints === iType)
                    return i18n.t('common.rewardPoints');
                else if (TransactionStatus.InterestRate === iType)
                    return i18n.t('pages.deposit.interestRate');
                else if (TransactionStatus.NewMember === iType)
                    return i18n.t('pages.deposit.registerNewMember');
                else
                    return i18n.t('pages.deposit.orderPayment');

            },
            onSearch() {               
                this.searchTransactionsOfRewardPoints(0,this.startDate, this.endDate);
            },
            onPageChanged(pageNo, reduced) {
                console.log('PageChanged', { pageNo, reduced });
                this.searchTransactionsOfRewardPoints(pageNo, this.startDate, this.endDate);
            },
        },       
        created() {                      
            this.onSearch();
        }
	}
</script>

