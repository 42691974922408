var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('ListForm',{attrs:{"headerTitle":_vm.$t('common.historyPoints'),"onSearch":_vm.onSearch},scopedSlots:_vm._u([{key:"searchBox",fn:function(){return [_c('CForm',[_c('CRow',[_c('CCol',{attrs:{"md":"5"}},[_c('div',{staticClass:"form-group form-row",attrs:{"role":"group"}},[_c('label',{staticClass:"col-form-label col-sm-3"},[_vm._v(_vm._s(_vm.$t('common.startDate'))+" ")]),_c('div',{staticClass:"col-sm-9"},[_c('date-picker',{attrs:{"format":"DD/MM/YYYY"},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1)])]),_c('CCol',{attrs:{"md":"7"}},[_c('div',{staticClass:"form-group form-row",attrs:{"role":"group"}},[_c('label',{staticClass:"col-form-label col-sm-3"},[_vm._v(_vm._s(_vm.$t('common.endDate'))+" ")]),_c('div',{staticClass:"col-sm-9"},[_c('date-picker',{attrs:{"format":"DD/MM/YYYY"},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)])])],1)],1)]},proxy:true},{key:"list",fn:function(){return [_c('CDataTable',{attrs:{"items":_vm.pointsTrabsactionList,"fields":_vm.fields,"hover":"","striped":"","border":"","small":"","fixed":"","loading":_vm.isLoading,"items-per-page":_vm.itemsPerPage},on:{"page-change":_vm.onPageChanged},scopedSlots:_vm._u([{key:"createdDate",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_c('label',{domProps:{"textContent":_vm._s(_vm.formatDate(item.createdDate))}})])]}},{key:"transactionType",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_c('label',{domProps:{"textContent":_vm._s(_vm.transactionType(item.transactionTypeId))}})])]}},{key:"deposit_detail_actions",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',{staticClass:"py-2"},[_c('CButton',{attrs:{"color":"primary","variant":"outline","square":"","size":"sm"},on:{"click":function($event){return _vm.onViewDetail(item, index)}}},[_vm._v(" "+_vm._s(_vm.$t('common.detail'))+" ")])],1)]}}])}),_c('CPagination',{attrs:{"pages":_vm.totalPages,"activePage":_vm.pageIndex + 1},on:{"update:activePage":_vm.onPageChanged}})]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }